import { AppBar, Button, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material'

import Link from 'next/link'

import React, { useContext } from 'react'
import { useRouter } from 'next/router'
import MenuIcon from '@mui/icons-material/Menu'
import { axiosContext } from '@/context/axios'
import { trainerContext } from '@/context/trainer'

export const Header = () => {
  const { trainer, setTrainer } = useContext(trainerContext)
  const { axiosInstance } = useContext(axiosContext)
  const router = useRouter()

  const logout = async () => {
    setTrainer(null)
    await axiosInstance.post('/api/logout')
    router.replace('/login')
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <AppBar
      position="static"
      sx={{ backgroundImage: 'linear-gradient(90deg, rgba(247, 93, 139, 1), rgba(254, 220, 64, 1))' }}
    >
      <Toolbar>
        <IconButton
          id="basic-button"
          size="large"
          edge="start"
          color="inherit"
          sx={{ mr: 2 }}
          aria-label="menu"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={() => router.replace('/')}>Top</MenuItem>
          {trainer?.role === 'master' && (
            <MenuItem onClick={() => router.replace('/individualLesson')}>個別指導スケジュール</MenuItem>
          )}
          {trainer?.role === 'master' && <MenuItem onClick={() => router.replace('/thread')}>質問一覧</MenuItem>}
        </Menu>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <Link href="/">あっぷっぷ トレーナー専用ページ</Link>
        </Typography>
        <Button color="inherit" onClick={logout}>
          LOGOUT
        </Button>
      </Toolbar>
    </AppBar>
  )
}
