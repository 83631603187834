import React, { FC } from 'react'
import Box from '@mui/material/Box'
import { Header } from '@/layouts/parts/MainLayout/Header'
import Container from '@mui/material/Container'

export const MainLayout: FC<React.ReactNode> = ({ children }) => {
  return (
    <Box sx={{ flexGrow: 1, backgroundImage: 'linear-gradient(90deg, rgba(85, 85, 85, 0.05), rgba(0, 0, 0, 0.05))' }}>
      <Header />
      <Container sx={{ py: 1 }} maxWidth={false}>
        {children}
      </Container>
    </Box>
  )
}
