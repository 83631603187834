import '@/styles/globals.css'

import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { NextComponentType } from 'next'
import React from 'react'

import { MainLayout } from '@/layouts/MainLayout'
import { UnauthenticatedLayout } from '@/layouts/UnauthenticatedLayout'
import { HtmlHead } from '@/layouts/common/HtmlHead'
import { MessageToaster } from '@/layouts/common/MessageToaster'
import { AuthProvider } from '@/providers/auth'
import { AxiosProvider } from '@/providers/axios'
import { SWRProvider } from '@/providers/swr'

import type { AppProps } from 'next/app'

const authLink = setContext((_, { headers }) => {
  const cookieToken = RegExp('XSRF-TOKEN[^;]+').exec(document.cookie)
  const token = decodeURIComponent(cookieToken ? cookieToken.toString().replace(/^[^=]+./, '') : '')
  return {
    headers: {
      ...headers,
      'X-XSRF-TOKEN': token,
    },
  }
})

const httpLinkUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8001/api/graphql' : '/api/graphql'
const httpLink = new HttpLink({
  uri: httpLinkUrl,
  credentials: 'include',
})

const cache = new InMemoryCache({
  typePolicies: {
    Challenge: {
      keyFields: ['id', 'category'],
    },
  },
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: cache,
})

type Props = AppProps & { Component: NextComponentType & { requireAuth: boolean } }

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          p: 0,
          // default = primary
          backgroundImage: 'linear-gradient(90deg, rgba(247, 93, 139, 1) 44%, rgba(254, 160, 64, 1));',
          '&.primary': {
            backgroundImage: 'linear-gradient(90deg, rgba(255, 0, 0, 1) 44%, rgba(0, 255, 0, 1));',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0px 0px 10px 2px rgba(0,0,0,0.1)',
          padding: '16px',
        },
      },
    },
  },
})

const App = ({ Component, pageProps }: Props) => {
  return (
    <ThemeProvider theme={theme}>
      <AxiosProvider>
        <SWRProvider>
          <ApolloProvider client={client}>
            <AuthProvider requireAuth={Component.requireAuth}>
              <HtmlHead />
              <MessageToaster />
              {Component.requireAuth ? (
                <MainLayout>
                  <Component {...pageProps} />
                </MainLayout>
              ) : (
                <UnauthenticatedLayout>
                  <Component {...pageProps} />
                </UnauthenticatedLayout>
              )}
            </AuthProvider>
          </ApolloProvider>
        </SWRProvider>
      </AxiosProvider>
    </ThemeProvider>
  )
}

export default App
