import { createContext } from 'react'
import axios, { AxiosInstance } from 'axios'

export type AxiosContextType = {
  axiosInstance: AxiosInstance
}

const defaultValue: AxiosContextType = {
  axiosInstance: axios.create(),
}

export const axiosContext = createContext(defaultValue)
