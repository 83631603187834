import React, { FC } from 'react'
import Box from '@mui/material/Box'
import { Header } from '@/layouts/parts/UnauthenticatedLayout/Header'

export const UnauthenticatedLayout: FC<React.ReactNode> = ({ children }) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Header />
      {children}
    </Box>
  )
}
