import React, { FC, useEffect, useState } from 'react'
import Router, { useRouter } from 'next/router'

import { Loading } from '@/components/common/Loading'
import { AuthTrainer, trainerContext } from '@/context/trainer'
import { useFindMeQuery } from '@/graphql/page/index'
import { ServerError } from '@apollo/client'

type Props = {
  requireAuth: boolean
}

export const AuthProvider: FC<Props> = ({ children, requireAuth }) => {
  const { data, loading, error, refetch } = useFindMeQuery()
  const router = useRouter()

  const [trainer, setTrainer] = useState<AuthTrainer | null>(null)

  useEffect(() => {
    if (loading === false) {
      const networkError = error?.networkError as ServerError | null
      if (networkError) {
        switch (networkError.statusCode) {
          case 401:
          case 419:
            if (requireAuth) {
              Router.replace('/login')
            }
            break
          default:
            Router.replace('/login')
        }

        return
      }

      if (requireAuth && !data?.me) {
        Router.replace('/login')
      }

      if (!requireAuth && data?.me) {
        Router.replace('/')
      }
    }

    setTrainer(data?.me as AuthTrainer | null)
  }, [loading, data, error])

  useEffect(() => {
    refetch()
  }, [router.pathname])

  if (loading || (requireAuth && !trainer)) {
    return <Loading />
  }

  return <trainerContext.Provider value={{ trainer, setTrainer }}>{children}</trainerContext.Provider>
}
