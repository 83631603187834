import { createContext } from 'react'
import { Trainer } from '@/graphql/models'

export type AuthTrainer = Pick<Trainer, 'id' | 'name' | 'role' | 'email' | 'individualLessonLink'>

export type TrainerContextType = {
  trainer: AuthTrainer | null | undefined
  setTrainer: React.Dispatch<React.SetStateAction<AuthTrainer | null>>
}

const defaultValue: TrainerContextType = {
  trainer: null,
  setTrainer: () => {},
}

export const trainerContext = createContext(defaultValue)
