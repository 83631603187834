import { AppBar, Avatar, Box, IconButton, Toolbar } from '@mui/material'
import React from 'react'

export const Header = () => {
  return (
    <AppBar position="static">
      <Toolbar>
        <Avatar
          variant="square"
          component="div"
          sx={{ width: 48, height: 48, mr: 1 }}
          src="/icons/corporate_logo.jpg"
        />
        <IconButton size="large" edge="start" color="inherit" aria-label="menu"></IconButton>
        <Box style={{ flexGrow: 1 }}></Box>
      </Toolbar>
    </AppBar>
  )
}
