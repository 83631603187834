import React, { FC } from 'react'
import axios from 'axios'
import { axiosContext, AxiosContextType } from '@/context/axios'

export const AxiosProvider: FC = ({ children }) => {
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
  axios.defaults.withCredentials = true
  const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8001/' : '/'
  const instance = axios.create({ baseURL: baseUrl })

  const ctx: AxiosContextType = {
    axiosInstance: instance,
  }

  return <axiosContext.Provider value={ctx}>{children}</axiosContext.Provider>
}
