import React, { FC, useContext } from 'react'
import { SWRConfig } from 'swr'

import { axiosContext } from '@/context/axios'

export const SWRProvider: FC = ({ ...props }) => {
  const { axiosInstance } = useContext(axiosContext)
  const fetcher = async (endpoint: string) => await axiosInstance(endpoint).then((res) => res.data)

  const config = {
    fetcher: fetcher,
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  }
  return <SWRConfig {...props} value={config} />
}
