import { Grid, LinearProgress, Typography } from '@mui/material'
import React from 'react'

export const Loading: React.VFC = () => {
  return (
    <Grid container alignItems="center" justifyContent="center" style={{ minHeight: '80vh' }}>
      <Grid item xs={3}>
        <Typography align="center">Loading</Typography>
        <LinearProgress />
      </Grid>
    </Grid>
  )
}
